import * as React from "react";
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import { FaChevronRight } from "react-icons/fa"
import { FaChevronLeft } from "react-icons/fa"

const config = require('../../data/siteConfig');
const styles = require ("./PostLIstTemplate.module.css");

class PostList extends React.Component {
    
    render(props:any) {        
        const posts = this.props.data.allMarkdownRemark.edges;
        const postList = this.props.data.allMarkdownRemark;
        const pageTitle="Комментарии к Евангелию";
        const postTypes = "Беседы с Рубеном Захарбековым (Шри Парамахансой Рамакришной)";
        const subTitle = "Порядок публикации комментариев соответствует последовательности повествования в Евангелии от Матфея. Публикации также включают рассмотрение параллельных эпизодов из других Евангелий и близких по значению эпизодов из других Святых Писаний.";
        //
        // Prev - Next navigation
        //
        const { currentPage, numPages,chapters } = this.props.pageContext;
        const isFirst = currentPage === 1;
        const isLast = currentPage === numPages;
        const prevPage = currentPage - 1 === 1 ? "/commentary/" : '/commentary/'+(currentPage - 1).toString();
        const nextPage = '/commentary/'+(currentPage + 1).toString();   

        return (
            <Layout>
                <div className={`${styles.pageNav}`}>     
                    <div className ={`${styles.pageNavMenu}`}>
                       
                    </div>
                </div>
                 <Helmet  defer={false}>
                    <title>{config.siteTitle}</title>
                    <link rel="canonical" href={`${config.siteUrl}`} />
                </Helmet>
                <div className="pageContent">
                    <h1>{pageTitle}</h1>
                    <h2>{postTypes}</h2>          
                    <p className="subTitle">{subTitle}</p>
                
                    {postList.edges.map(({ node }:any, i:number) => (
                    <Link to={`${node.fields.slug}`} key={i} className="link link-to-post" >
                        <div className="post-list-item">
                        <h3>{node.frontmatter.title}</h3>
                        <span className="postDate">{node.frontmatter.date}</span>
                        <p>{node.excerpt}</p>
                        </div>
                    </Link>
                    ))}
                    <div className={`${styles.Pagination}`}>                    
                  {!isFirst && (
                        <Link to={prevPage} rel="prev">
                         <FaChevronLeft /><span></span>
                        </Link>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                        <Link key={`pagination-number${i + 1}`} to={`/commentary/${i === 0 ? "" : i + 1}`}>
                        {i + 1}
                        </Link>
                    ))}
                    {!isLast && (
                        <Link to={nextPage} rel="next">
                       <span></span><FaChevronRight />
                        </Link>
                    )}   
                </div>  
                </div>                               
            </Layout>           
        )
    }
}
export default PostList;

export const commentListQuery = graphql`
    query commentListQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark( 
            filter: {frontmatter: {posttype: {eq: "comment"}}} 
            sort: {fields: [frontmatter___quote], order: ASC}
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    fields {
                        slug
                    }
                    frontmatter {
                        date
                        title
                        posttype
                    }
                }
            }
        }
    }
`